import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowBack, ArrowForward, Cached } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { CustomButton } from "ndr-designsystem";
import { DateTime } from "luxon";
import { useAppSelector } from "app/hooks";
import { RootState } from "app/store";
import useStyles from "./styles";
import { FieldWithGridProps, PageStatus } from "../utils/types";
import DeactivateDialog from "./DeactivateDialog";

interface Props extends FieldWithGridProps {
    isGeneralTabValid?: boolean,
    isControllabilityTabValid?: boolean,
    disabled: boolean,
    disableEditWhenDeactivated: boolean,
    disableEnhanceWhenDeactivated: boolean,
    isDeactivatedInFuture: boolean,
    onAddClick?: () => void
    onCancelClick?: () => void
    onSaveClick?: () => void
    onEditClick?: () => void
    onRefresh?: () => void
    onEnhance?: () => void
    isForGroup: boolean,
    onNextClick?: () => void
    onPreviousClick?: () => void
    onDeactivateClick?: (existenceEnd: DateTime) => void
    isLoadingHolidays?: boolean;
}

const DetailsViewToolbar = ({
    isGeneralTabValid,
    isControllabilityTabValid,
    pageStatus,
    disabled,
    disableEditWhenDeactivated,
    disableEnhanceWhenDeactivated,
    isDeactivatedInFuture,
    onAddClick,
    onSaveClick,
    onCancelClick,
    onEditClick,
    onRefresh,
    onEnhance,
    onDeactivateClick,
    isForGroup,
    onNextClick, onPreviousClick,
    isLoadingHolidays
}: Props): ReactElement => {

    const classes = useStyles();
    const { t } = useTranslation("tables");
    const previousMessage = t(`toolbar_buttons.${onPreviousClick !== undefined ? "" : "no_"}previous_${isForGroup ? "group" : "resource"}`)
    const nextMessage = t(`toolbar_buttons.${onNextClick !== undefined ? "" : "no_"}next_${isForGroup ? "group" : "resource"}`)
    const [openDialog, setOpenDialog] = useState(false);

    // check if it is a control group or a controllable resource
    const isControlGroup = useAppSelector((state: RootState) => state.controlGroups.currentGroup !== undefined);
    const publicHolidaysfromState = useAppSelector((state: RootState) => isControlGroup ? state.controlGroups.publicHolidays : state.controllableResources.publicHolidays);

    const isAddButtonEnabled = isGeneralTabValid && isControllabilityTabValid;

    return (
        <div className={classes.titleContainer}>
            {onDeactivateClick && <DeactivateDialog
                title={isForGroup ? t("control_groups_details.deactivate") : t("controllable_resources_details.deactivate")}
                open={openDialog}
                isLoadingHolidays={isLoadingHolidays}
                publicHolidays={publicHolidaysfromState ?? undefined}
                onAgree={async (existenceEnd: DateTime): Promise<void> => {
                    onDeactivateClick(existenceEnd);
                    setOpenDialog(false);
                }}
                onCancel={(): void => { setOpenDialog(false) }}
            />}
            <div className={`${classes.addNewRightButton} ${classes.addNewTitleButton}`}>
                <div>
                    {(onRefresh && pageStatus === PageStatus.VIEW) &&
                        <IconButton color="primary" component="span" onClick={onRefresh}>
                            <Cached color="primary" />
                        </IconButton>
                    }
                </div>
                {pageStatus === PageStatus.ADD && onAddClick && (
                    <CustomButton
                        title={t("toolbar_buttons.add")}
                        disabled={!isAddButtonEnabled}
                        onClick={onAddClick}
                    />
                )}
                {pageStatus === PageStatus.EDIT &&
                    <>
                        <div />
                        <div>
                            {onCancelClick &&
                                <CustomButton title={t("toolbar_buttons.cancel")}
                                    onClick={onCancelClick} />
                            }
                            {onSaveClick &&
                                <CustomButton title={t("toolbar_buttons.save")} disabled={disabled}
                                    onClick={onSaveClick} />
                            }
                        </div>
                    </>
                }
                {pageStatus === PageStatus.VIEW &&
                    <div>
                        {onEnhance &&
                            <CustomButton
                                title={t(`toolbar_buttons.${isForGroup ? "confirmGroup" : "approveEnhancedData"}`)}
                                onClick={onEnhance} disabled={disableEnhanceWhenDeactivated} />
                        }
                        {onEditClick &&

                            <CustomButton title={t("toolbar_buttons.edit")} onClick={onEditClick} disabled={disableEditWhenDeactivated} />

                        }
                        {onDeactivateClick &&
                            <CustomButton title={isDeactivatedInFuture ? t("toolbar_buttons.deactivate_adjust") : t("toolbar_buttons.deactivate")} className={classes.deactivateButton} onClick={() => setOpenDialog(true)} />
                        }
                        <Tooltip
                            enterDelay={250}
                            title={previousMessage}
                        >
                            <span>
                                <IconButton color="primary" onClick={onPreviousClick} disabled={!onPreviousClick}>
                                    <ArrowBack />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip
                            title={nextMessage}
                        >
                            <span>
                                <IconButton color="primary" component="button" onClick={onNextClick}
                                    disabled={onNextClick === undefined}>
                                    <ArrowForward />
                                </IconButton>
                            </span>
                        </Tooltip>

                    </div>
                }
            </div>
        </div>
    );
};

DetailsViewToolbar.defaultProps = {
    onRefresh: undefined,
    onEnhance: undefined,
    onEditClick: undefined,
    onNextClick: undefined,
    onPreviousClick: undefined,
    onSaveClick: undefined,
    onAddClick: undefined,
    onCancelClick: undefined,
    onDeactivateClick: undefined,
    isGeneralTabValid: true,
    isControllabilityTabValid: true,
    isLoadingHolidays: false,
};

export default DetailsViewToolbar;