import React, { ReactElement, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/lab";
import { DateTime } from "luxon";
import { ClosableDialog, isSchemaValid, toLocaleDate } from "ndr-designsystem";
import { DeactivationSchema } from "utils/inputChecking";
import { useInternationalization } from "@progress/kendo-react-intl";
import useStyles from "./styles";
import AlertDialog from "./AlertDialog/SimpleAlertDialog";
import { useAppDispatch } from "../app/hooks";
import { fetchPublicHolidays } from "../features/controllableResources/store/thunks";


interface Props {
    onAgree: (existenceEnd: DateTime) => void;
    onCancel: () => void;
    title: string;
    open: boolean;
    isLoadingHolidays?: boolean;
    publicHolidays?: Array<string>;
}

const DeactivateDialog = ({ onAgree, title, open, onCancel, isLoadingHolidays, publicHolidays }: Props): ReactElement => {
    const { t } = useTranslation("tables");
    const dispatch = useAppDispatch();
    
    useEffect(() => {
        if (!publicHolidays) {
            dispatch(fetchPublicHolidays());
        }
    }, [dispatch, publicHolidays]);

    // we're only interested in the date, not the time
    const cleanDate = (date: DateTime): DateTime => date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    const [existenceEnd, setExistenceEnd] = useState<DateTime | null>(cleanDate(DateTime.now().plus({ days: 1 })));
    const [alertOpen, setAlertOpen] = useState<boolean>(false);
    const classes = useStyles();
    const internationalization = useInternationalization();
    const Schema = DeactivationSchema();
    const isValid = isSchemaValid(Schema, { existenceEnd });

    // Prevent TextField recording keypresses
    const onKeyDown = (e: React.KeyboardEvent): void => {
        e.preventDefault();
    };

    const isWeekend = (date: DateTime): boolean => {
        const day = date.weekday; // Luxon's DateTime uses 'weekday' where 1 is Monday and 7 is Sunday
        return day === 6 || day === 7; // Saturday and Sunday
    };

    return (
        <div>
            <AlertDialog
                title={title}
                text={t("deactivate_dialog.alert_text", {
                    date: toLocaleDate(existenceEnd!, internationalization.locale, DateTime.DATE_SHORT),
                })}
                open={alertOpen}
                acceptText={t("deactivate_dialog.buttons.deactivate")}
                cancelText={t("deactivate_dialog.buttons.cancel")}
                onAgree={async () => {
                    setAlertOpen(false);
                    onAgree(existenceEnd!);
                }}
                onCancel={() => {
                    setAlertOpen(false);
                    onCancel();
                }}
            />
            <ClosableDialog
                open={open}
                onClose={onCancel}
                contentPadding={2}
                dialogTitle={<DialogTitle style={{ paddingLeft: 0 }}>{title}</DialogTitle>}
            >
                <form autoComplete="off" onSubmit={(): void => {}}>
                    <FormControl className={classes.deactivateForm} error={isValid.error !== undefined}>
                        <DesktopDatePicker
                            renderInput={(params) => <TextField onKeyDown={onKeyDown} name="existenceEnd" variant="standard" {...params} />}
                            shouldDisableDate={(date: DateTime): boolean => {
                                if (isLoadingHolidays) return false;
                                // Check if date is a weekend
                                if (isWeekend(date)) return true;
                                const holidays = publicHolidays ?? [];
                                return holidays.some((holiday: string) => {
                                    // Convert holiday string to DateTime
                                    const holidayDate = DateTime.fromISO(holiday);
                                    return holidayDate.hasSame(date, 'day');
                                });
                            }}
                            value={existenceEnd}
                            InputProps={{
                                readOnly: true,
                                disabled: true,
                            }}
                            label={t(`deactivate_dialog.deactivate_date`)}
                            minDate={DateTime.now().plus({ days: 1 })}
                            onChange={(date: DateTime | null): void => {
                                if (date !== null) {
                                    const cleanedDate = cleanDate(date);
                                    setExistenceEnd(cleanedDate);
                                }
                            }}
                        />
                        <FormHelperText>{isValid.error ? t(`${isValid.error}`) : ""}</FormHelperText>
                    </FormControl>
                    <DialogActions>
                        <Button onClick={onCancel} color="primary">
                            {t("deactivate_dialog.buttons.cancel")}
                        </Button>
                        <Button
                            onClick={() => existenceEnd && setAlertOpen(true)}
                            color="primary"
                            disabled={!isValid.valid}
                        >
                            {t("deactivate_dialog.buttons.deactivate")}
                        </Button>
                    </DialogActions>
                </form>
            </ClosableDialog>
        </div>
    );
};

DeactivateDialog.defaultProps = {
    isLoadingHolidays: false,
    publicHolidays: [],
};

export default DeactivateDialog;


