// Overwrite setting for DEV from local env settings if defined
const isDevEnv = process.env.NODE_ENV === "development";
const useLocalConfig = isDevEnv && process.env.REACT_APP_USE_LOCAL_CONFIGURATION === 'true'

const envConfig = {
    ...window,
    // Overwrite setting for DEV from local env settings if defined
    ...(useLocalConfig ? process.env : undefined),
} as any;

const Config =
    {
        auth: {
            authority: envConfig.REACT_APP_AUTHN_AUTHORITY || "",
            client_id: envConfig.REACT_APP_AUTHN_CLIENTID || "",
            scope: envConfig.REACT_APP_AUTHN_SCOPE || ""
        },
        basename: envConfig.REACT_APP_SUB_DIRECTORY || "",
        masterDataService: {
            endpoint: envConfig.REACT_APP_API_MASTERDATA_SERVICE_URL || ""
        },
        planningDataService: {
            endpoint: envConfig.REACT_APP_API_PLANNINGDATA_SERVICE_URL || ""
        },
        activationsService: {
            endpoint: envConfig.REACT_APP_API_ACTIVATIONS_SERVICE_URL || ""
        },
        downtimeService: {
            endpoint: envConfig.REACT_APP_API_DOWNTIME_SERVICE_URL || ""
        },
        tasksService: {
            endpoint: envConfig.REACT_APP_API_TASKS_SERVICE_URL || ""
        },
        connectPlusMessagesService: {
            endpoint: envConfig.REACT_APP_CONNECTPLUS_SERVICE_URL || ""
        },
        language: envConfig.REACT_APP_LANGUAGE || undefined
    }

// eslint-disable-next-line
// console.log('Used configuration: ', Config);

export default Config;
