import { createAsyncThunk } from "@reduxjs/toolkit";
import { isEqual } from "lodash";
import { resetGeneratedCostInfoState } from "features/timeseries/generatedCostInfo/store/thunks";
import { AxiosError } from "axios";
import i18n from "i18next";
import { DateTime } from "luxon";
import { RootState } from "../../../app/store";
import { ControlGroup } from "../../../api/fixed/ControlGroup/ControlGroup";
import { removeNulls } from "../../../utils";
import Api from "../../../api/Api";
import {
    addGroupToArray,
    overwriteGroupInArray,
    setAllGroups,
    setCurrentGroup,
    updateGroupInArray,
    updateStatusInArray,
    setPublicHolidays
} from "./controlGroupsSlice";
import { setAllSensitivities } from "../../simplePlanningData/sensitivities/store/slice";
import { resetReceivedMessages, resetSentMessages } from "../../ConnectPlusMsgs/ConnectPlusMessagesSlice";
import { fetchAllControlGroupsAndResources } from "../../appStateSlice";
import { setAllActivations } from "../../timeseries/activations/store/store";
import ControlGroupStatus from "../../../api/fixed/ControlGroup/ControlGroupStatus";
import { resetGeneratedSensitivityState } from "../../timeseries/generatedSensitivities/store/thunks";

export const fetchControlGroups = createAsyncThunk(
    'controlGroups/fetch',
    async (force: boolean, { getState, dispatch }) => {
        const state: RootState = getState() as RootState;
        let response: ControlGroup[];

        if (state.controlGroups.allGroups && state.controlGroups.allGroups.length > 0 && !force)
            response = [...state.controlGroups.allGroups];
        else
            response = removeNulls(await Api.fetchControlGroups());

        dispatch(setAllGroups(response))
    }
)

export const fetchControlGroupById = createAsyncThunk(
    'controlGroups/fetchById',
    async ({ id, force, setAsCurrent }: { id: string, force: boolean, setAsCurrent: boolean }, { getState, dispatch }) => {
        const state: RootState = getState() as RootState;
        let group;
        let fromMemory = true;

        group = state.controlGroups.allGroups?.find(g => g.inventoryItemId === id || g.externalID === id);

        if (!group || force) {
            let response;
            if (group) {
                response = (await Api.fetchControlGroupById(group.inventoryItemId!));
            } else {
                response = (await Api.fetchControlGroupById(id));
            }
            fromMemory = false;
            group = removeNulls(response);
        }

        if (setAsCurrent) {
            dispatch(setCurrentGroup(group || null));
        }

        if (!fromMemory) {
            dispatch(overwriteGroupInArray(group))
        }
        return group;
    }
)

export const fetchPublicHolidays = createAsyncThunk(
    'controlGroups/fetchPublicHolidays',
    async (_: never, { dispatch }) => {
            const response = await Api.fetchPublicHolidays();
            const holidays = response.map(date => 
                date instanceof DateTime ? date.toISO() : date
            );
            dispatch(setPublicHolidays(holidays));
            return holidays;
    }
);

export const confirmGroup = createAsyncThunk(
    "controlGroups/confirm",
    async (id: string, { dispatch }) => {
        await Api.confirmCG(id);
        dispatch(updateStatusInArray(ControlGroupStatus.Confirmed))
    }
)

export const addNewControlGroup = createAsyncThunk(
    'controlGroups/addNew',
    async (group: ControlGroup, { dispatch, rejectWithValue }) => {
        try {
            const response = await Api.addControlGroup(group)
            dispatch(addGroupToArray({
                inventoryItemId: `${response.data}`,
                revision: 0, ...group,
                status: ControlGroupStatus.Created
            }))
        } catch (e) {
            const error = e as AxiosError
            return rejectWithValue({
                status: error.response?.status,
                detail: error.response?.status === 409 ? i18n.t('validation:external_id_not_duplicate') : ""
            })
        }
        return true;
    }
)

export const deactivateControlGroup = createAsyncThunk(
    "controlGroups/deactivate",
    async ({ id, existenceEnd }: { id: string; existenceEnd: DateTime }, { dispatch, rejectWithValue, getState }) => {
        try {
            await Api.deactivateControlGroup(id, existenceEnd);
            await fetchControlGroupById({ id, force: true, setAsCurrent: true })(dispatch, getState, undefined);
        } catch (e) {
            const error = e as AxiosError;
            return rejectWithValue({
                status: error.response?.status,
                detail: "",
            });
        }
        return true;
    },
);

export const updateControlGroup = createAsyncThunk(
    'controlGroups/update',
    async (group: ControlGroup, { dispatch, rejectWithValue }) => {
        try {
            await Api.updateControlGroup(group)
            dispatch(updateGroupInArray());
        } catch (e) {
            const error = e as AxiosError
            return rejectWithValue({
                status: error.response?.status,
                detail: error.response?.status === 409 ? i18n.t('validation:external_id_not_duplicate') : ""
            })
        }
        return true;
    }
)

export const resetCGBeforeEdit = createAsyncThunk(
    "controlGroups/resetBeforeEdit",
    async (_: never, { dispatch, getState }) => {
        const state = getState() as RootState;
        if (!state.controlGroups.currentGroup) return;

        dispatch(fetchControlGroupById({
            id: state.controlGroups.currentGroup.inventoryItemId!,
            setAsCurrent: true,
            force: false,
        }))
    })

export const addControllableResourceToControlGroup = createAsyncThunk(
    'controlGroups/addControllableResourceToControlGroup',
    async (request: { groupId: string, resourceId: string }, { getState, dispatch }) => {
        const state: RootState = getState() as RootState;

        const currentGroup: ControlGroup = { ...state.controlGroups.allGroups?.find(g => g.inventoryItemId === request.groupId)! };

        if (!currentGroup) return;

        if (currentGroup.controllableResources)
            currentGroup.controllableResources = [`${request.resourceId}`, ...currentGroup.controllableResources]
        else
            currentGroup.controllableResources = [`${request.resourceId}`]

        await Api.updateControlGroup(currentGroup);

        if (currentGroup && currentGroup.revision) {
            currentGroup.revision += 1;
        }

        dispatch(overwriteGroupInArray(currentGroup));
        dispatch(setCurrentGroup(currentGroup));
    }
)

export const removeControllableResourceFromControlGroup = createAsyncThunk(
    'controlGroups/removeControllableResourceFromControlGroup',
    async (request: { groupId: string, resourceId: string }, thunkAPI) => {
        const state: RootState = thunkAPI.getState() as RootState;
        // eslint-disable-next-line eqeqeq
        const currentGroup: ControlGroup = { ...state.controlGroups.allGroups?.find(g => g.inventoryItemId == request.groupId)! };

        if (!currentGroup) return undefined;

        if (!currentGroup.controllableResources)
            return currentGroup;

        currentGroup.controllableResources = currentGroup.controllableResources.filter(x => x !== request.resourceId);

        const response = await Api.updateControlGroup(currentGroup);
        if (response.status !== 200) return undefined;
        return currentGroup;
    }
)

export const fetchControlGroupStatusById = createAsyncThunk(
    'controlGroups/fetchStatusById',
    async (id: string, { getState, dispatch }) => {
        const response = await Api.fetchControlGroupStatus(id);
        const state = getState() as RootState;
        if (!isEqual(state.controlGroups.currentGroup?.status, response)) {
            dispatch(updateStatusInArray(response));
        }
    }
)

export const isCRInCG = () => (dispatch: any, getState: any): boolean => {
    const state: RootState = getState();
    const { allGroups } = state.controlGroups;
    const { currentResource } = state.controllableResources;
    return allGroups?.find(cg => cg.controllableResources?.includes(currentResource?.inventoryItemId ?? "")) !== undefined
}

export const resetCGState = createAsyncThunk("controlGroups/resetState",
    (_, { dispatch }) => {
        dispatch(setAllSensitivities(undefined))
        dispatch(setAllActivations(undefined));
        dispatch(resetSentMessages());
        dispatch(resetReceivedMessages());
        dispatch(resetGeneratedCostInfoState())
        dispatch(resetGeneratedSensitivityState())
    })

export const buildCGState = createAsyncThunk("controlGroups/buildState",
    async ({ id, force }: { id: string, force: boolean }, { dispatch }): Promise<void> => {
        await dispatch(fetchAllControlGroupsAndResources({ force, fetchGroups: true, fetchResources: true }))

        return dispatch(fetchControlGroupById({ id, force, setAsCurrent: true })).unwrap();
    })

export const forceRefreshCG = createAsyncThunk("controlGroups/forceRefresh",
    async (_: never, { dispatch, getState }) => {
        const state = getState() as RootState;
        if (state.controlGroups.currentGroup && state.controlGroups.currentGroup.inventoryItemId) {
            await dispatch(buildCGState({
                id: state.controlGroups.currentGroup.inventoryItemId,
                force: true,
            }));
        }
    })
